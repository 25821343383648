import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { Link, graphql } from "gatsby"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title} | 猫背で進め</title>
        <meta name="description" content={post.excerpt} />
        <meta
          property="og:title"
          content={post.frontmatter.title + " | 猫背で進め"}
        />
        <meta property="og:description" content={post.excerpt} />
        <meta
          property="og:image"
          content={`${data.site.siteMetadata.siteUrl}${post.frontmatter.seoImage
            ?.publicURL || "/images/ogp.png"}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.frontmatter.title} />
        <meta
          name="twitter:image"
          content={`${data.site.siteMetadata.siteUrl}${post.frontmatter.seoImage
            ?.publicURL || "/images/ogp.png"}`}
        />
      </Helmet>
      <div>
        <div
          css={css`
            color: #bbb;
          `}
        >
          {post.frontmatter.date} / {post.frontmatter.category}
        </div>
        <h1>{post.frontmatter.title}</h1>
        <p>
          カテゴリ:&nbsp;
          <Link to={`/category/${post.frontmatter.category}`}>
            {post.frontmatter.category}
          </Link>
        </p>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr />
        <Link to={`/category/${post.frontmatter.category}`}>
          {post.frontmatter.category} の記事一覧
        </Link>
        &nbsp;/&nbsp;
        <Link to="/">すべての記事</Link>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(truncate: true)
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        title
        category
        seoImage {
          publicURL
        }
      }
    }
  }
`
